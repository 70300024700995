<template>
 <v-container fluid :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
      <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false"  />
      <v-card  :color="$store.getters.getColorPalette().background2ColorCode"  :dark="$store.getters.getColorPalette().isDark">
          <v-card-title>
          <v-icon  color="yellow"  small></v-icon>&nbsp;<strong style="align-self:centre" :color="$store.getters.getColorPalette().accentCode"> {{ $store.getters.getTextMap().update_your_oem_logo }}</strong>
        </v-card-title>
  <v-row>
    <v-col>
    <v-file-input
      v-model="fileToUpload"
      :label="$store.getters.getTextMap().select_a_logo_to_upload"
      accept="image/*"
      @change="uploadLogo"
    ></v-file-input>
    </v-col>

      <!-- Display the selected image -->
      <v-col>
    <v-img
      v-if="logoUrl"
      :src="logoUrl"
      alt="Uploaded Logo"
      width="200"
    ></v-img>
    </v-col>
     <v-col>
    
  </v-col>
  </v-row>
  <v-row>
   
  <v-col><v-btn :color="$store.getters.getColorPalette().submitbtnColor" v-if="!loading" @click="submitLogo()">{{ $store.getters.getTextMap().submit_logo }}</v-btn></v-col>
  <v-col><v-btn :color="$store.getters.getColorPalette().cancelbtnColor" v-if="!loading" @click="onClose()">{{  $store.getters.getTextMap().cancel }}</v-btn></v-col>
                    
  </v-row>
      </v-card>
 </v-container>
</template>

<script>
import axios from 'axios';
import InfoAlert from '@/components/InfoAlert'

export default {
    name:'AddOEMLogo',
    components:{
InfoAlert,

    },
  data() {
    return {
      logoUrl: null,
      fileToUpload: null,
      showDismissibleAlert:false,
      info:'',
      loading:false,
      logoUploaded: false,
      dialog1:false,
    };
  },
  methods: {
   uploadLogo() {
  if (this.fileToUpload) {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.logoUrl = e.target.result;
    };

    reader.readAsDataURL(this.fileToUpload);
  }
},

onClose(){
            this.dialog1=false
            this.$emit('close')
        },


submitLogo() {
    if (!this.fileToUpload) {
      this.info = 'Please select a file before submitting.';
      this.showDismissibleAlert = true;
      return;
    }
  if (this.fileToUpload) {
    console.log("Submitting logo...");
    this.loading = true;
    const reader = new FileReader();

    reader.onload = (e) => {
    //   console.log("File read successfully.");

      const originalSizeBytes = e.target.result.length;
      const originalSizeKB = originalSizeBytes / 1024; 
      console.log("Original Image Size: " + originalSizeKB + " KB");

      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800; 
        const maxHeight = 800; 
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        const compressedBase64 = canvas.toDataURL("image/jpeg", 0.7); 

      
        const compressedSizeBytes = compressedBase64.length;
        const compressedSizeKB = compressedSizeBytes / 1024; 
        console.log("Compressed Image Size: " + compressedSizeKB + " KB");

        const requestData = {
          oem_logo: compressedBase64,
        };

        // console.log("Sending request data:", requestData);

        axios
          .post(this.$store.state.api + "updateOEMLogo", requestData, {
            headers: { Authorization: 'Bearer ' + this.$store.state.jwt },
          })
          .then((response) => {
            // console.log("Response data:", response.data);
            if (response.data.status === 'success') {
              this.info = 'OEM Logo updated successfully';
              this.logoUrl = e.target.result;
              this.logoUploaded = true;
              this.$store.dispatch('refreshOrganisation')
              this.$emit('logo-updated', e.target.result);
            } else {
              this.info = response.data.msg;
            }
            this.showDismissibleAlert = true;
            this.loading = false;
          })
          .catch((error) => {
            console.log("Error:", error);
            this.info = error.response.data.msg;
            this.showDismissibleAlert = true;
          })
          .finally(() => {
            this.loading = false;
          });
      };
    };

    reader.readAsDataURL(this.fileToUpload);
  }
}


  },
};
</script>

<style scoped>
.round-logo-frame {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; 
}

.round-logo {
  width: 100%;
  height: 100%;
   object-fit: contain; 
}
</style>
