<template>
    <v-container fluid>
        <v-row  v-if="$store.state.user &&  $store.getters.getAccessByLevels(['engineeraccess'])">
            <v-col cols="12">
                <AddOEMLogo />
            </v-col>
            <v-col>
<!-- <LogoDisplay/> -->
                </v-col>
        </v-row>
    </v-container>
</template>

<script>
import AddOEMLogo from '@/components/account_components/AddOEMLogo'
// import LogoDisplay from '@/components/display_components/LogoDisplay'
export default {
    name:'AddOEMLogoView',
    data(){
        return {}
    },
    components:{
        AddOEMLogo,
        // LogoDisplay
    },
    mounted() {
         if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }
    },
}
</script>
<style scoped>
.container{
    margin-top: -1%;
}
</style>